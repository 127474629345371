import React, { useEffect } from "react"
import Like from "../images/like.svg"
import Layout from "../components/layout"
import IntroVector1 from "../images/about1.svg"
import IntroVector2 from "../images/about2.svg"
import IntroVector3 from "../images/about3.svg"
import SEO from "../components/seo"
import Parallax from "parallax-js"

const IndexPage = () => {
  useEffect(() => {
    parallaxIcon()
  })

  function parallaxIcon() {
    const scene = document.getElementById("introIllustration")
    const parallaxInstance = new Parallax(scene)

    console.log(parallaxInstance)
  }
  return (
    <Layout>
      <SEO title="Parteaguas" />
      <div className="intro">
        <div
          className="intro-quote"
          data-sal="slide-right"
          data-sal-delay="600"
          data-sal-easing="ease"
        >
          <h3 className="intro-quote__title">
            Ésa gran sacudida en tu vida que marca un antes y un después.
            Adversidad. Cambio. Resiliencia.
          </h3>
          <p className="intro-quote__subtitle">
            Celina Canales entrevista a personas que han atravesado el camino de
            la supervivencia hasta la plenitud. Escucha para crear la mejor
            versión de ti.
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="button facebookLike"
            href="https://www.facebook.com/"
          >
            <img src={Like} alt="Like" /> Like
          </a>
        </div>
        <div data-sal="slide-left" data-sal-delay="600" data-sal-easing="ease">
          <div id="introIllustration">
            <div className="illustrationMiddle" data-depth="0.5">
              <img
                className="intro-quote__illustration"
                src={IntroVector2}
                alt="Draw"
              />
            </div>
            <div className="illustrationBack" data-depth="0.9">
              <img
                className="intro-quote__illustration"
                src={IntroVector1}
                alt="Draw"
              />
            </div>
            <div className="illustrationFront" data-depth="0.2">
              <img
                className="intro-quote__illustration"
                src={IntroVector3}
                alt="Draw"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
